export const init = () => {
  document.querySelectorAll('.js-comma-separated-number').forEach((input) => {
    if (input.value) {
      input.value = KTUtil.numberString(input.value.replaceAll(',', ''));
    }

    input.addEventListener('blur', (event) => {
      event.target.value = KTUtil.numberString(event.target.value.replaceAll(',', ''));
    });

    input.addEventListener('focus', (event) => {
      event.target.value = event.target.value.replaceAll(',', '');
    });
  });
};
