'use strict';

// 与えられた文字列を全角から半角変換、カンマを削除し、Number型で返す
const toNumber = (value) => {
  if (!value) {
    return Number(value);
  }

  return Number(String(value).replace(/[０-９．，]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 65248);
  }).replace(/,/g, ''));
};

export const mathUtil = {
  toNumber(value) {
    return toNumber(value);
  },
};
