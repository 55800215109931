'use strict';

import translations_ja from './translations.ja.json';
import translations_en from './translations.en.json';

let translations = {
  ...translations_ja,
  ...translations_en,
};

const DEFAULT_LOCALE = 'ja';
const PLACEHOLDER_REGEX = /\$\{([^}]+)\}/g;

function extractPlaceholders(text) {
  const placeholders = Array.from(text.matchAll(PLACEHOLDER_REGEX)).map(m => m[1]);
  return Array.from(new Set(placeholders)).sort();
}

function arrayEquals(a, b) {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export const i18n = {
  // テスト時に任意の翻訳を渡すためのメソッド
  set_translations(tr) {
    translations = tr;
  },

  get locale() {
    return (typeof gon === 'undefined' || gon.locale === undefined) ? DEFAULT_LOCALE : gon.locale;
  },

  get environment() {
    return (typeof gon === 'undefined' || gon.environment === undefined) ? 'development' : gon.environment;
  },

  // 使うべきロケールを優先度の高い方から順に列挙したリストを返す
  get locales() {
    if (this.environment === 'production' && this.locale !== DEFAULT_LOCALE) {
      // fallback to the default
      return [this.locale, DEFAULT_LOCALE];
    } else {
      return [this.locale];
    }
  },

  t(key, variables = {}) {
    let translation = undefined;
    for (const l of this.locales) {
      if (translations[l] === undefined) {
        continue;
      }
      const candidate = translations[l][key];
      if (candidate !== undefined) {
        translation = candidate;
        break;
      }
    }
    if (translation === undefined) {
      return `translation missing: ${this.locale}.${key}`;
    }

    const placeholderList = extractPlaceholders(translation);
    const variableList = Array.from(new Set(Object.keys(variables))).sort();

    if (arrayEquals(placeholderList, variableList)) {
      // 変数の置換に成功
      return translation.replaceAll(PLACEHOLDER_REGEX, (_, placeholder) => variables[placeholder]);
    }

    const missingPlaceholders = placeholderList.filter(p => !variableList.includes(p));
    if (missingPlaceholders.length > 0) {
      return `missing interpolation arguments: ${this.locale}.${key}, variable:${missingPlaceholders.join(',')}`;
    }
    const extraVariables = variableList.filter(a => !placeholderList.includes(a));
    if (extraVariables.length > 0) {
      return `extra interpolation arguments: ${this.locale}.${key}, variable:${extraVariables.join(',')}`;
    }
  },
};
